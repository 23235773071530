<template>
  <div id="data-model-container" class="vs-con-loading__container">
    <vs-popup
        :active.sync="isSliceReportVisible"
        :buttons-hidden="true"
        :button-close-hidden="true"
        title="Key Data Report"
        @close="previewKeyDataReportForSlice({KeyData: []}, false)"
      >
      <div>
        <vs-table stripe :data="sliceReport.KeyData">
          <template slot="thead">
            <vs-th>Keyword</vs-th>
            <vs-th>Count</vs-th>
          </template>
          <template slot-scope="{data}">
            <tbody>
              <tr
                v-for="row in sliceReport.KeyData"
                :key="row[0]"
              >
                <vs-td>
                  <p class="product-name font-medium">{{row[0]}}</p>
                </vs-td>
                <vs-td>
                  <p>{{row[1]}}</p>
                </vs-td>
              </tr>
            </tbody>
          </template>
        </vs-table>
      </div>
      </vs-popup>
    <div id="data-list-list-view" class="data-list-container">
      <vs-table stripe :data="itemData.slices" :hover-flat="true">
        <template slot="header">
          <div class="header-title-container flex items-center">
            <!-- ADD NEW -->
            <feather-icon 
              icon="ArrowLeftIcon" 
              class="cursor-pointer mr-2"
              svgClasses="w-5 h-5 hover:text-danger stroke-current"
              @click.stop="goBack()" 
            />
            <span class="header-title">{{itemData.fileName}}</span>
          </div>
          <div>Firm: {{itemData.lawFirmName}}</div>
          <div>Case: {{itemData.caseName}}</div>
        </template>
          <template slot="thead">
            <vs-th v-for="header in headerItems" :key="header" class="text-center">{{header}}</vs-th>
          </template>
          <template slot-scope="{data}">
            <tbody>
              <vs-tr 
                :data="tr" 
                :key="indextr" v-for="(tr, indextr) in itemData.slices"
              >
                <vs-td>
                  <p class="product-name font-medium">{{ tr.title }}</p>
                </vs-td>
                <vs-td>
                  <p>{{tr.groupType ? tr.groupType.join(", ") : ""}}</p>
                </vs-td>
                <vs-td>
                  <p>{{tr.pageRange ? tr.pageRange.map(page => (page+1)).join(", ") : ""}}</p>
                </vs-td>
                <vs-td>
                  <p>{{tr.KeyData ? tr.KeyData.slice(0, 3).map(item => (item[0] + "(" + item[1] + ")")).join(", ") : ""}}</p>
                </vs-td>
                <vs-td>
                  <vx-tooltip text="View All Keywords" position="left" color="#1a1a1a">
                    <feather-icon icon="EyeIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" class="ml-2"
                                  @click.stop="previewKeyDataReportForSlice(tr, true)" />
                  </vx-tooltip>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
    </div>
  </div>
</template>

<script>
import moduleTodo from '@/store/todo/moduleTodo.js'
import _ from 'lodash'

export default {
  components: {
    
  },
  data () {
    return {
      itemData: {slices: []},
      sliceReport: {KeyData: []},
      isSliceReportVisible: false,
      headerItems: ["Title", "Group Types", "Page Range", "Top Keywords", "Actions"],
      accountId: this.$route.params.accountId,
      caseId: this.$route.params.caseId,
      documentId: this.$route.params.documentId
    }
  },
  created() {
    if (!this.$store.hasModule("todo")) {
      this.$store.registerModule('todo', moduleTodo)
    }
  },
  mounted () {
    this.$vs.loading({
      container: '#data-model-container',
    });
    this.$store.dispatch('todo/fetchCaseDocumentKeyDataReport', {
          accountId: this.accountId,
          caseId: this.caseId,
          documentId: this.documentId
        }).then(response => {
          if (response && response.slices) {
            response.slices = response.slices.map(slice => {
              if (slice.KeyData) {
                var sortable = [];
                for (var keyword in slice.KeyData) {
                  sortable.push([keyword, slice.KeyData[keyword]]);
                }

                sortable.sort(function(a, b) {
                  return b[1] - a[1];
                });
                slice.KeyData = sortable;
              }
              return slice;
            })
          }
          this.itemData = response
          console.log(response, this.itemData);
          this.$vs.loading.close('#data-model-container > .con-vs-loading')
        }).catch(error => {
          this.$vs.loading.close('#data-model-container > .con-vs-loading')
        });
  },
  methods: {
    handleNavigation() {
      try {
        if (this.$route.query.source) {
          switch(this.$route.query.source) {
            case "account-case-documents":
            case "case-document-readyforslicing":
            case "case-document-full-bates":
              this.$router.push({name: this.$route.query.source, params: {caseId: this.caseId, accountId: this.accountId}})  
              return true;
          }
        }
      } catch(error) {}
      return false;
    },
    goBack() {
      let result = this.handleNavigation();
      if (!result) {
        this.$router.push({name: "account-case-documents", params: {caseId: this.caseId, accountId: this.accountId}})
      }
    },
    previewKeyDataReportForSlice(sliceGroup, isActive) {
      this.isSliceReportVisible = isActive
      this.sliceReport = sliceGroup
    }
  },
}
</script>

<style>
  .vs-tooltip{
    padding: 8px;
  }
  #data-list-list-view .vs-con-table .vs-table--header {
    margin: 1rem;
    font-size: 12px;
  }

  .vs-table--search {
    max-width: 100%;
    width: 100%;
  }

  #data-list-list-view .vs-con-table .vs-table--header .vs-table--search .vs-table--search-input {
    padding: 0.65rem 2rem;
    width: 100%;
    margin-right: 18px;
  }

  #data-list-list-view .vs-con-table .vs-table--header .vs-table--search .vs-table--search-input + i,
  #data-list-list-view .vs-con-table .vs-table--header .vs-table--search .vs-table--search-input:focus + i {
    left: 0.5rem;
  }

  .vs-table--td {
    padding: 10px 20px !important;
  }

  .header-table {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-title-container {
    flex-grow: unset;
  }

  .header-title-container .feather-icon {
    height: 20px;
    margin-right: 8px;
  }

  .vs-table--tbody-table .tr-values.selected {
    cursor: auto;
  }

  .vs-table--tbody-table a {
    cursor: pointer;
    text-decoration: underline;
  }
  .vs-popup {
    overflow: hidden;
    max-width: 75vw !important;
    max-height: 80vh !important;
  }
</style>